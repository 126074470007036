<template>
  <tr v-show="!item.is_edit" @click="openEditLine(index)">
    <td class="">{{ Object.keys(props.item.customer).length ? item.customer.name : 'Select Customer' }}</td>
    <td class="">{{ item.product ? item.product.name : 'Select Product' }}</td>
    <td class="text-center">{{ item.unit_of_measurment }}</td>
    <td class="text-center">{{ item.min_order_qtn }}</td>
    <td class="text-center">{{ item.slab_qtn }}</td>
    <td class="text-center">{{ item.bonus_qty }}</td>
    <td class="text-center">{{ item.discount_amount }}</td>
    <td class="text-center">{{ item.offer_start_date }}</td>
    <td class="text-center">{{ item.offer_end_date }}</td>
    <td class="text-center text-capitalize">
      <div :class="['status-dropdown', item.status.toLowerCase()]">
        {{ item.status }}
      </div>
    </td>
    <td>
      <div>
        <button @click="(e) => deleteItem(e, index)" class="btn btn-sm btn-dlt">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>
  <tr v-show="item.is_edit" :class="item.is_edit && 'tr-box-shadow tr-edit'">
    <td style="min-width: 250px">
      <AsyncSelect
        placeholder="Select Customer"
        v-model="selectedContact"
        :api-service="fetchContactProfiles"
        :format-label="formatPatientLabel"
        :option-selected="onchangeContact"
        :additional-query="customerQuery"
        label="name"
      />
    </td>
    <td style="min-width: 250px">
      <AsyncSelect
        :api-service="fetchProductList"
        placeholder="Select Product"
        v-model="selectedProduct"
        :format-label="option => option.text"
        label="text"
        :option-selected="onSaveItemHandler"
      />
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model="item.unit_of_measurment"
          @blur="onSaveItemHandler"
          type="text" class="form-control text-center"
          placeholder="Unit"
          readonly
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model.trim="item.min_order_qtn"
          @blur="onSaveItemHandler"
          type="number"
          class="form-control text-center"
          placeholder="Qty"
          :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model.trim="item.slab_qtn"
          @blur="onSaveItemHandler"
          type="number"
          class="form-control text-center"
          placeholder="Rate"
          :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model="item.bonus_qty"
          @blur="onSaveItemHandler"
          name="amount text-right"
          type="number"
          class="form-control text-center"
          :min="0"/>
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model="item.discount_amount"
          @blur="onSaveItemHandler"
          name="amount text-right"
          type="number"
          class="form-control text-center"
          :min="0"/>
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model.trim="item.offer_start_date"
          @blur="onSaveItemHandler"
          type="date"
          class="form-control text-center"
          placeholder=""
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <vField
          v-model="item.offer_end_date"
          @blur="onSaveItemHandler"
          name="discount-amount"
          type="date"
          class="form-control text-center"
        />
      </div>
    </td>
    <td class="text-center">
      <div :class="['status-dropdown', item.status.toLowerCase()]" class="td-inline">
        <select v-model="item.status" class="form-control text-center" @change="onSaveItemHandler">
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    </td>
    <td>
      <div>
        <button @click="(e) => deleteItem(e, index)" class="btn btn-sm btn-dlt">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>

</template>
<script setup>
import {ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import handleContact from "@/services/modules/contact";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

const props = defineProps(['item', 'index', 'onOpenEditItem', 'onDeleteItem'])
const emit = defineEmits(['onOpenEditItem', 'onDeleteItem', 'onSaveItem']);

const {fetchProductList} = handlePurchaseAndSales()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper()

const selectedProduct = ref(props.item.product);
const selectedContact = ref(Object.keys(props.item.customer).length ? props.item.customer : null);
const customerQuery= ref({
    with_mobile: 1,
    with_donors: 1,
    dealer_id: '',
    roles: 'customer,lead'
});

const onchangeContact = () => {
  if (selectedContact.value) {
    props.item.contact_profile_id = selectedContact.value.id;
    onSaveItemHandler()
    return;
  }

  props.item.contact_profile_id = null;
  onSaveItemHandler();
}

watch(selectedProduct, (newValue) => {
  if (newValue) {
    props.item.product = {
      name: newValue.text,
      ...newValue
    }
    props.item.unit_of_measurment = newValue.description.unit.name;
    props.item.product_id = newValue.id;
    onSaveItemHandler();
  }
});

const openEditLine = (i) => {
  emit('onOpenEditItem', i)
}

const deleteItem = (e, i) => {
  e.stopPropagation();
  emit('onDeleteItem', i)
}

const onSaveItemHandler = () => {
  emit('onSaveItem', props.item);
}

</script>
<style scoped>
.btn-dlt {
  color: #ea5455;
}

.tr-edit td {
  min-width: 80px;
}

.status-dropdown {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  min-width: 100px;
}

.status-dropdown select {
  background: transparent;
  border: none;
  color: inherit;
  font-weight: inherit;
  padding: 0;
  width: 100%;
  appearance: none;
}

.status-dropdown.active {
  background-color: #d4f7dc;
  color: #28a745;
}

.status-dropdown.inactive {
  background-color: #f7d4d4;
  color: #dc3545;
}
</style>