<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton :showBtn="true" :showAddNew="false" title="Sales Offer Setup" @onClickCloseButton="goToList" />
        </div>

        <div class="my-2 px-2">
            <TableHeaderSalesOfferAdd @handleSearch="handleSearch" />
        </div>

        <div class="col-12 px-2">
            <ListTable
              :tableItems="tableItems"
              :getSalesOffers="getSalesOfferSetup"
              @addNewItem="addNewItem"
              @onSaveItem="onSaveItemHandler"
            />
        </div>
        <div class="px-2">
            <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination" />
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import { onMounted, computed, provide, reactive, ref, inject } from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/inventory/sales-offer/InvoiceSalesOfferTable.vue';
import { useRoute, useRouter } from 'vue-router';
import handlePurchase from '@/services/modules/purchase';
import Pagination from '@/components/atom/Pagination'
import TableHeaderSalesOfferAdd from "@/components/molecule/company/inventory/sales-offer/TableHeaderSalesOfferAdd.vue";
import useDate from "@/services/utils/day";
import {useStore} from "vuex";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";

const { fetchSalesOfferSetup, storeSalesOfferSetup, updateSalesOfferSetup } = handlePurchase();
const showError =  inject('showError');
const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const { currentDate } = useDate()

const companyId = $route.params.companyId;
const totals = ref({});
let offset = ref(5)
let tableItems = ref([]);

let formData = ref({
    productId: null,
});

provide('formData', formData);
provide('totals', totals);
const page = computed(() => {
    return $route.query.page
})

function getQuery(additionalQuery = {}) {
    let queryObject = Object.assign({}, {
      company_id: companyId,
      offset: offset.value,
    })

    if (page.value) {
      queryObject.page = page.value
    }

    if (Object.keys(additionalQuery).length > 0) {
      queryObject = Object.assign(queryObject, additionalQuery);
    }

    return '?' + new URLSearchParams(queryObject).toString();
}

const setPagination = (data) => {
  $store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  });
}

const getSalesOfferSetup = (additionalQuery) => {
  tableItems.value = [];
  const query = getQuery(additionalQuery);

  fetchSalesOfferSetup(query)
    .then(res => {
      if (res.data) {
        res.data.data.map((offer) => {
          tableItems.value.push({
            id: offer.id,
            product: {
              ...offer.product,
              text: offer.product.name
            }, // Assuming 'offer.product' is the product object
            product_id: offer.product_id,
            customer: {
              ...offer.customer,
            }, // Assuming 'offer.product' is the product object
            contact_profile_id: offer.contact_profile_id,
            name: offer.product ? offer.product.name : '', // Assuming 'offer.product.name' is the name
            unit_of_measurment: offer.unit_of_measurment || 'pcs',
            min_order_qtn: offer.min_order_qtn || 1,
            slab_qtn: offer.slab_qtn,
            bonus_qty: offer.bonus_qty,
            discount_amount: offer.discount_amount || 0,
            offer_start_date: offer.offer_start_date || currentDate(),
            offer_end_date: offer.offer_end_date || currentDate(),
            status: offer.status || 'active',
            is_edit: false,
          });
        })
        setPagination(res.data)
      }
    }).catch((err) => {
      console.log(err)
  });
}

const addNewItem = () => {

    tableItems.value.push({
        id: null,
        product: null,
        customer: {},
        product_id: null,
        name: '',
        unit_of_measurment: 'pcs',
        min_order_qtn: 1,
        slab_qtn: 1,
        bonus_qty: 1,
        offer_start_date: currentDate(),
        offer_end_date: currentDate(),
        status: 'active',
        is_edit: false,
    });

}

const goToList =  () => {
    $router.push({
        name: 'sales-offer-setup',
        params: {
            companyId: $route.params.companyId,
            moduleId: $route.params.moduleId,
            menuId: $route.params.menuId,
            pageId: $route.params.pageId
        },
        query: {
            start: $route.query.start,
            end: $route.query.end
        }

    })
}

async function onPageChange(page) {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  await $router.push({path: $route.path, query: routeQuery})
  getSalesOfferSetup();
}

const onSaveItemHandler = (indexOrItem) => {
    let item;
    if (typeof indexOrItem === 'object') {
        item = indexOrItem;
    } else {
        item = tableItems[indexOrItem];
    }

    item['company_id'] = companyId;

    let copyItemData = Object.assign({}, item)

    try {
        if (item.id) {
            updateSalesOfferSetup(item.id, copyItemData)
                .then(({status, data, message}) => {
                    if (!status) {
                      return showError(message)
                    }
                    item.id = data.id;
                })

            return;
        }
        storeSalesOfferSetup(copyItemData)
            .then(({status, data, message}) => {
                if (!status) {
                  return showError(message)
                }
                item.id = data.id;
            })

        item.is_edit = false;
    } catch (error) {
    }
};

const handleSearch = (data) => {
  if (!data) {
    getSalesOfferSetup();
    return;
  }

  const additionalQuery = {};

  if (data.product_id) {
    additionalQuery.product_id = data.product_id;
  }

  if (data.status && data.status !== '') {
    additionalQuery.status = data.status;
  }

  getSalesOfferSetup(additionalQuery);
}

onMounted(() => {
  getSalesOfferSetup()
});

</script>
