<template>

  <div class="row">
    <div class="row h-40 mb-5">
      <div class="col-md-5">
        <AsyncSelect
          :api-service="fetchProductList"
          placeholder="Select Product"
          v-model="productId"
          label="text"
          :format-label="option => option.text"
          :reduce="text => text.id"
        />
      </div>

      <div class="col-md-2">
        <div :class="['status-dropdown', status.toLowerCase()]" class="td-inline">
          <select v-model="status" class="form-control">
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>

      <div class="col-md-4">
        <button class="btn btn-primary" @click="searchHandler">
          Go
        </button>
      </div>
    </div>
    <Loader v-if="isLoader"/>

  </div>

</template>

<script setup>
import {ref} from 'vue'
import handlePurchase from '@/services/modules/purchase'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import Loader from "@/components/atom/LoaderComponent";

const emit = defineEmits(['handleSearch']);
const isLoader = ref(false);
const productId = ref(null);
const status = ref('');
const {fetchProductList} = handlePurchase()

const searchHandler = () => {
  emit('handleSearch', {product_id: productId.value, status: status.value})
}

</script>

<style>
.select label {
  font-size: 14px;
}

.h-40 {
  height: 40px;
  align-items: center;
}
</style>